import React from 'react';
import '../scss/_menu.scss'

interface MenuProps {
  images?: string | string[]
  pdfs?: string | string[]
}

export const Menu: React.FC<MenuProps> = ({images, pdfs}) => {
  const imageList = Array.isArray(images) ? images : [images];
  const pdfList = Array.isArray(pdfs) ? pdfs : [pdfs];
  if (!imageList.length && !pdfList.length) throw Error('Put some images/pdfs in you silly goose.');
  return (
    <>
      {pdfs && pdfList.map(pdf => <iframe src={pdf} style={{ minHeight: "100vh", minWidth: "100vw" }} />)}
      {imageList.map(image => <img src={image}  alt={'menu image'}/>)}
    </>
  )
}
